.startedGameOverlay {
  position: absolute;
  height: 80%;
  z-index: 111;
}
.blueOverlay {
  position: absolute;
  height: 100%;
  z-index: 888;
  width: 100%;
}
#target {
  filter: blur(5px);
}
.pointsEarned {
  font-size: 50px;
}
.timerValue {
  font-size: 50px;
  text-shadow: 2px 4px 14px -1px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  letter-spacing: 1px;
}
.cardImgDone {
  height: 100%;
  max-width: 325px;
  object-fit: cover;
  filter: blur(3px);
}
/* @media screen and (max-width: 600px) {

  .indicatorLg{
    height: 10px;
    width: 10px;
  }
} */
