.velevtBg {
  background: linear-gradient(180deg, #8a284d 0%, #9e304c 100%);
}

.bottomBarContainer {
  height: 50px;
  position: sticky;
  bottom: 0;
  left: 0;
}
